import './Dashboard.css';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ChatsPanel() {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const location = useLocation();
  const chatContainerRef = useRef(null);
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
	const params = new URLSearchParams(location.search);
	const roomId = params.get('selectedRoomId');
	if (roomId) {
	  setSelectedRoomId(roomId);
	  fetchChatLogs(roomId);
	}
  }, [location.search]);

  const fetchChatLogs = async (roomId) => {
	if (!roomId) return;
	setLoading(true);
	try {
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/logs/${roomId}`, {
		headers: {
		  'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`,
		  'x-api-key': API_KEY,
		},
	  });

	  if (!response.ok) {
		throw new Error('Network response was not ok');
	  }

	  const logsData = await response.json();
	  setLogs(logsData);
	} catch (error) {
	  console.error('Failed to fetch chat logs:', error);
	} finally {
	  setLoading(false);
	}
  };

  const mxcUrlToHttpUrl = (messageBody) => {
	const match = messageBody.match(/mxc:\/\/[^\/]+\/([^ )]+)/);
	if (match) {
	  const [, mediaId] = match;
	  return `https://flymedi.nokt.xyz/_matrix/media/r0/download/flymedi.nokt.xyz/${mediaId}`;
	}
	return null;
  };

  const formatDate = (isoString) => {
	const options = {
	  year: 'numeric',
	  month: 'short',
	  day: 'numeric',
	  hour: '2-digit',
	  minute: '2-digit',
	  hour12: true,
	};
	return new Date(isoString).toLocaleDateString(undefined, options);
  };

  const exportChatLogs = () => {
	const chatText = logs.map(log => `[${formatDate(log.timestamp)}] ${log.user}: ${log.body}`).join('\n');
	const blob = new Blob([chatText], { type: 'text/plain;charset=utf-8' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = `chat_history_${selectedRoomId}.txt`;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
  };

  const exportImages = () => {
	const imageUrls = logs
	  .filter((message) => {
		// Include messages where the user's name contains digits
		return /\d/.test(message.user);
	  })
	  .map((message) => {
		const mxcUrlMatch = message.body.match(/(mxc:\/\/[^ ]+)/);
		if (mxcUrlMatch) {
		  const httpImageUrl = mxcUrlToHttpUrl(mxcUrlMatch[1]);
		  if (
			message.body.startsWith('image') ||
			message.body.includes('.jpg') ||
			message.body.includes('.jpeg') ||
			message.body.includes('Flymedi Photo Guide') ||
			message.body.includes('Flymedi Male Nose') ||
			message.body.includes('Flymedi Female Nose')
		  ) {
			return httpImageUrl;
		  }
		}
		return null;
	  })
	  .filter((url) => url !== null);

	if (imageUrls.length === 0) {
	  alert('No images found in the chat history.');
	  return;
	}

	imageUrls.forEach((url, index) => {
	  fetch(url)
		.then((response) => response.blob())
		.then((blob) => {
		  const downloadUrl = window.URL.createObjectURL(blob);
		  const a = document.createElement('a');
		  a.href = downloadUrl;
		  a.download = `image_${index + 1}.jpg`;
		  document.body.appendChild(a);
		  a.click();
		  a.remove();
		  window.URL.revokeObjectURL(downloadUrl);
		})
		.catch((error) => {
		  console.error('Failed to download image:', error);
		});
	});
  };

  // Function to process user names
  const processUserName = (user) => {
	const strippedUser = user.split(':')[0];
	if (strippedUser === 'botwa2') return 'Sophia';
	if (strippedUser === 'botwa') return 'Sophia';
	return strippedUser;
  };

  const sortedLogs = logs.sort((a, b) => new Date(a.formattedTimestamp) - new Date(b.formattedTimestamp));

  return (
	<div className="chat-page">
	  <div className="chat-panel">
		<div className="button-container">
		  <button className="export-chat-history-button" onClick={exportChatLogs}>💾 Export</button>
		  <button className="export-images-button" onClick={exportImages}>🖼️ Export Images</button>
		</div>
		{loading ? (
		  <div className="loading-indicator"></div>
		) : (
		  <div className="chat-container" ref={chatContainerRef}>
			{sortedLogs.map((message, messageIndex) => {
			  const isOutgoing = !/\d{3,}/.test(message.user);
			  const mxcUrlMatch = message.body.match(/(mxc:\/\/[^ ]+)/);
			  const httpImageUrl = mxcUrlMatch ? mxcUrlToHttpUrl(mxcUrlMatch[1]) : null;

			  return (
				<div key={messageIndex} className={`chat-message ${isOutgoing ? 'right' : 'left'}`}>
				  <div className="chat-user">{processUserName(message.user)}</div>
				  <div className="chat-body">
					{httpImageUrl ? (
					  message.body.startsWith('image') ||
					  message.body.includes('.jpg') ||
					  message.body.includes('.jpeg') ||
					  message.body.includes('Flymedi Photo Guide') ||
					  message.body.includes('Flymedi Male Nose') ||
					  message.body.includes('Flymedi Female Nose') ? (
						<img src={httpImageUrl} alt="Image" style={{ maxWidth: '100%' }} />
					  ) : message.body.startsWith('video.mp4') ? (
						<video controls style={{ maxWidth: '100%' }}>
						  <source src={httpImageUrl} type="video/mp4" />
						  Your browser does not support the video tag.
						</video>
					  ) : message.body.startsWith('audio.ogg') ? (
						<audio controls>
						  <source src={httpImageUrl} type="audio/ogg" />
						  Your browser does not support the audio element.
						</audio>
					  ) : (
						<span>{message.body}</span>
					  )
					) : (
					  <span>{message.body}</span>
					)}
				  </div>
				  <div className="chat-timestamp">{formatDate(message.formattedTimestamp)}</div>
				</div>
			  );
			})}
		  </div>
		)}
	  </div>
	</div>
  );
}

export default ChatsPanel;
